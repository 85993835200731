import {Component, OnInit} from '@angular/core';
import {OrderService} from "../services/order.service";
import {Order} from "../models/order";
import {Router} from "@angular/router";
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit{
  orders: Order[] = [];
  currentPage: number = 1;

  filteredOrders: Order[] = [];
  pageSize: number = 10; // Default to 10 items per page
  totalOrders: number = 0;
  selectedDeliveryStatus: string = '';

  constructor(
    private orderService: OrderService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.fetchOrders(this.currentPage, this.pageSize);
  }

  fetchOrders(page: number, limit: number): void {
    this.orderService.getLimOrders(page, limit).subscribe({
      next: (response) => {
        this.orders = response.data; // Correctly access data and total from the response
        this.totalOrders = response.total;
        this.applyFilter();
      },
      error: (e) => console.error(e)
    });
  }

  applyFilter(): void {
    // Define the valid delivery statuses for default view
    const defaultValidStatuses = ['', 'Droppex', 'Tik-Tak', 'First Delivery'];

    // Filter orders that match default valid statuses
    const filteredByDefault = this.orders.filter(order =>
      defaultValidStatuses.includes(order.deliveredTo)
    );

    if (this.selectedDeliveryStatus && this.selectedDeliveryStatus !== '') {
      // Further filter by the selected delivery status if one is selected
      this.filteredOrders = filteredByDefault.filter(order =>
        order.deliveredTo === this.selectedDeliveryStatus
      );
    } else {
      // Use the already filtered list if no specific filter is applied
      this.filteredOrders = filteredByDefault;
    }
  }

  navigateToOrder(orderId: string): void {
    this.router.navigate(['/orders', orderId]);
  }

  onPageChange(event: PageEvent): void {
    this.currentPage = event.pageIndex + 1; // Page index is 0-based, add 1 for your API if it's 1-based
    this.pageSize = event.pageSize;
    this.fetchOrders(this.currentPage, this.pageSize);
  }
}
