import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiResponse, Order} from '../models/order';
@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  getOrders(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/orders/`);
  }
  getLimOrders(page: number, limit: number): Observable<ApiResponse<Order[]>> {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('limit', limit.toString());
    return this.http.get<ApiResponse<Order[]>>(`${environment.apiUrl}/orders/lim/`, { params });
  }
  getOrderById(orderId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/orders/${orderId}`);
  }

  sendOrderToDelivery(orderId: string | null): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/orders/sendOrderToFirst/${orderId}`,
      {} // Passing an empty object as the body
    );
  }

  sendOrderToTikTak(orderId: string | null): Observable<any> {
    // If you need to send a body, include it as the second parameter here
    const body = {}; // Adjust this based on what the API expects, if anything
    return this.http.post<any>(`${environment.apiUrl}/orders/sendOrderToTikTak/${orderId}`, body);
  }
  sendOrderToDroppex(orderId: string | null): Observable<any> {
    // If you need to send a body, include it as the second parameter here
    const body = {}; // Adjust this based on what the API expects, if anything
    return this.http.post<any>(`${environment.apiUrl}/orders/sendOrderToDroppex/${orderId}`, body);
  }

  resetDelivery(orderId: string | null) {
    const body = {}; // Adjust this based on what the API expects, if anything
    return this.http.post<any>(`${environment.apiUrl}/orders/resetDelivery/${orderId}`, body);

  }
  addGov(orderId: string | null, selectedGov: string) {
    const body = {}; // Adjust this based on what the API expects, if anything
    return this.http.post<any>(`${environment.apiUrl}/orders/addGov/${orderId}/${selectedGov}`, body);

  }
  deleteOrder(orderId: string | null) {
    return this.http.delete(`${environment.apiUrl}/orders/${orderId}`);
  }

  sendOrderToAramex(orderId: string | null) {
    // If you need to send a body, include it as the second parameter here
    const body = {}; // Adjust this based on what the API expects, if anything
    return this.http.post<any>(`${environment.apiUrl}/orders/sendOrderToAramex/${orderId}`, body);
  }
}
