import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {OrderService} from "../services/order.service";

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  orderId!: string | null;
  order: any; // Adjust based on your order model
  selectedGov: string = '';
  governments: string[] = ['Tunis', 'Ariana', 'Béja', 'Ben arous', 'Bizerte', 'Gabes', 'Gafsa', 'Jendouba', 'Kairouan', 'Kasserine', 'Kébili', 'Manouba', 'kef', 'Mahdia', 'Médenine', 'Monastir', 'Nabeul', 'Sfax', 'Sidi Bouzid', 'Sousse', 'Tataouine', 'Touzeur', 'Zaghouan'];
  isLoading: boolean = false;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private orderService: OrderService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.orderId = this.route.snapshot.paramMap.get('orderId');
    if (this.orderId) {
      this.loadOrderDetails(this.orderId);
    }
  }

  loadOrderDetails(orderId: string): void {

    this.isLoading = true;
    // Implement fetching order details by ID from your OrderService
    this.orderService.getOrderById(orderId).subscribe({
      next: (data) => this.order = data,
      error: (e) => console.error(e)
    });
    this.isLoading = false;
  }

  goBackToList(): void {
    this.router.navigate(['/orders']);
  }

  sendToFirst() {
    this.isLoading = true;
    if (confirm('Are you sure you want to send this order to First?')) {
      this.orderService.sendOrderToDelivery(this.orderId).subscribe({
        next: (response) => {
          console.log('Order sent to delivery:', response);
          this.isLoading = false;
          window.location.reload();
        },
        error: (error) => {
          this.isLoading = false;
          if (error.status === 501) {
            alert(error.error.error); // Display the error message to the user
          } else {
            console.error('Error sending order to delivery:', error);
          }
        }
      });
    }
  }

  sendToTikTak() {
    this.isLoading = true;
    if (confirm('Are you sure you want to send this order to TikTak?')) {
      this.orderService.sendOrderToTikTak(this.orderId).subscribe(response => {
        console.log('Order sent to delivery:', response);
        this.isLoading = false;
        window.location.reload();
      }, error => {
        this.isLoading = false;
        console.error('Error sending order to delivery:', error);
      });
    }
  }

  sendToDroppex() {
    this.isLoading = true;
    if (confirm('Are you sure you want to send this order to Droppex?')) {
      this.orderService.sendOrderToDroppex(this.orderId).subscribe(response => {
        console.log('Order sent to delivery:', response);
        this.isLoading = false;
        window.location.reload();
      }, error => {
        this.isLoading = false;
        console.error('Error sending order to delivery:', error);
      });
    }
  }

  addGov() {
    this.isLoading = true;
    this.orderService.addGov(this.orderId, this.selectedGov).subscribe(response => {
      console.log('Order sent to delivery:', response);
      window.location.reload();
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      console.error('Error sending order to delivery:', error);
    });
  }
  resetDelivery() {
    this.isLoading = true;
    this.orderService.resetDelivery(this.orderId).subscribe(response => {
      this.isLoading = false;
      window.location.reload();
    }, error => {
      this.isLoading = false;
      console.error('Error reset Delivery order to delivery:', error);
    });
  }
  deleteOrder(){
    if (confirm('Are you sure you want to Delete this order?')) {
    this.isLoading = true;
    this.orderService.deleteOrder(this.orderId).subscribe({
      next: (response) => {
      }
    })
    this.isLoading = false;
      this.router.navigate(['/orders']);
  }}
  openAramexUrl(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }

  sendToAramex() {
    this.isLoading = true;
    if (confirm('Are you sure you want to send this order to Droppex?')) {
      this.orderService.sendOrderToAramex(this.orderId).subscribe(response => {
        console.log('Order sent to delivery:', response);
        this.isLoading = false;
        window.location.reload();
      }, error => {
        this.isLoading = false;
        console.error('Error sending order to delivery:', error);
      });
    }
  }
}
