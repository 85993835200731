<div class="order-actions">
  <button (click)="goBackToList()" class="action-button return-button">Return to List</button>
</div>
<div *ngIf="isLoading" class="loading-section">
  <mat-spinner diameter="50"></mat-spinner>
  <p>Loading Order Details...</p>
</div>
<div *ngIf="order && !isLoading" class="order-details">
  <h2>Order Details</h2>
  <p><strong>Order Number:</strong> {{ order.orderData.data.orderNumber }}</p>
  <p><strong>Payment Amount:</strong> {{ order.orderData.data.payments[0].amount.value }} {{ order.orderData.data.payments[0].amount.currency }}</p>
  <p *ngIf="order.isDelivered"><strong>Order has been sent to:</strong> {{ order.deliveredTo }}</p>
  <button *ngIf="order.AramexUrl" (click)="openAramexUrl(order.AramexUrl)" class="action-button reset-button">Shipping PDF</button>
  <button *ngIf="order.isDelivered" (click)="resetDelivery()" class="action-button reset-button">Reset Delivery</button>
  <button (click)="deleteOrder()" class="action-button delete-button">Delete this Order</button>

  <h3>Line Items</h3>
  <ul>
    <li *ngFor="let item of order.orderData.data.lineItems" class="line-item">
      <img [src]="item.image" alt="Product Image" class="product-image">
      <h4><strong>  Item Name:</strong> {{ item.itemName }}  </h4>
      <p><strong>  Quantity:</strong> {{ item.quantity }}  </p>
      <p><strong>  Total Price:</strong> {{ item.totalPrice.value }} {{ item.totalPrice.currency }}  </p>
      <p><strong>  Size:</strong> {{ item.descriptionLines[0]?.description }}  </p>
      <p><strong>  Color:</strong> {{ item.descriptionLines[1]?.description }}  </p>
    </li>
  </ul>

  <h3>Shipping Info</h3>
  <p><strong>Delivery Time:</strong> {{ order.orderData.data.shippingInfo.logistics.deliveryTime }}</p>
  <p><strong>Shipping Address:</strong> {{ order.orderData.data.shippingInfo.logistics.shippingDestination.address.formattedAddress }}</p>
  <p><strong>Contact:</strong> {{ order.orderData.data.shippingInfo.logistics.shippingDestination.contactDetails.firstName }} {{ order.orderData.data.shippingInfo.logistics.shippingDestination.contactDetails.lastName }}</p>
  <p><strong>Phone:</strong> {{ order.orderData.data.shippingInfo.logistics.shippingDestination.contactDetails.phone }}</p>

  <h3>Billing Info</h3>
  <p><strong>City:</strong> {{ order.orderData.data.billingInfo.address.city }}</p>
  <p><strong>Address:</strong> {{ order.orderData.data.billingInfo.address.addressLine }}</p>
  <p *ngIf="order.gov"><strong>Gouvernorat:</strong> {{ order.gov }}</p>
  <p><strong>Contact Name:</strong> {{ order.orderData.data.billingInfo.contactDetails.firstName }} {{ order.orderData.data.billingInfo.contactDetails.lastName }}</p>
  <p><strong>Phone:</strong> {{ order.orderData.data.billingInfo.contactDetails.phone }}</p>

  <p><strong>Order Created At:</strong> {{ order.createdAt | date:'medium' }}</p>

  <div *ngIf="!order.isDelivered" class="government-selection">
    <p *ngIf="!order.gov">
      <label for="govSelect"><strong>Select Government:</strong></label>
      <select id="govSelect" [(ngModel)]="selectedGov">
        <option *ngFor="let gov of governments" [value]="gov">{{ gov }}</option>
      </select>
      <button (click)="addGov()" class="action-button add-gov-button">Add</button>
    </p>
  </div>
</div>
<div *ngIf="order.gov">
  <div class="order-actions" *ngIf="!order.isDelivered">
    <button (click)="sendToFirst()" class="action-button send-first-button">Send to First</button>
    <button (click)="sendToTikTak()" class="action-button send-tiktak-button">Send to TikTak</button>
    <button (click)="sendToDroppex()" class="action-button send-droppex-button">Send to Droppex</button>
    <button (click)="sendToAramex()" class="action-button send-aramex-button">Send to Aramex</button>
  </div>
</div>
<div *ngIf="!order.gov" class="warning">
  <h4>Sélectionnez Gouvernorat avant d'envoyer en livraison</h4>
</div>
