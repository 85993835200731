<div *ngIf="orders.length; else noOrdersTemplate">
  <h2>Orders List</h2>
  <table class="beautiful-table">
    <thead>
    <tr>
      <th>Order Number</th>
      <th>Amount</th>
      <th>Line Items Count</th>
      <th>Contact Details</th>
      <th>Shipping Address</th>
      <th>Delivery Status</th>
      <th>Date Created</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let order of orders; let i = index" (click)="navigateToOrder(order._id)" class="clickable-row" tabindex="0">
    <td>{{ order.orderData.data.orderNumber }}</td>
      <td>{{ order.orderData.data.payments[0]?.amount.value }} {{ order.orderData.data.payments[0]?.amount.currency }}</td>
      <td>{{ order.orderData.data.lineItems.length }}</td>
      <td>
        {{ order.orderData.data.shippingInfo.logistics.shippingDestination.contactDetails.firstName }}
        {{ order.orderData.data.shippingInfo.logistics.shippingDestination.contactDetails.lastName }}
        - {{ order.orderData.data.shippingInfo.logistics.shippingDestination.contactDetails.phone }}
      </td>
      <td>
        {{ order.orderData.data.shippingInfo.logistics.shippingDestination.address.addressLine }},
        {{ order.orderData.data.shippingInfo.logistics.shippingDestination.address.city }},
        {{ order.orderData.data.shippingInfo.logistics.shippingDestination.address.postalCode }}
      </td>
      <td>
        <img src="assets/First.jpg" alt="First" *ngIf="order.deliveredTo == 'First Delivery'" style="width: 2rem; height: 2rem">
        <img src="assets/Tik-Tak.jpg" alt="Tik-Tak" *ngIf="order.deliveredTo == 'Tik-Tak'" style="width: 2rem; height: 2rem">
        <img src="assets/Droppex.jpg" alt="First" *ngIf="order.deliveredTo == 'Droppex'" style="width: 2rem; height: 2rem">
        <img src="assets/aramex.png" alt="First" *ngIf="order.deliveredTo == 'Aramex'" style="width: 2rem; height: 2rem">
        {{ order.deliveredTo || " not yet "}}
      </td>
      <td>{{ order.createdAt | date:'medium' }}</td>
    </tr>
    </tbody>
  </table>
  <mat-paginator [length]="totalOrders"
                 [pageSize]="pageSize"
                 [pageSizeOptions]="[5, 10, 20]"
                 (page)="onPageChange($event)">
  </mat-paginator>


</div>

<ng-template #noOrdersTemplate>
  <p>No orders found.</p>
</ng-template>
