<div class="card">
    <h5 class="card-header">Change Password</h5>
    <div class="card-body">
        <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
            <div class="mb-3">
                <label class="form-label">Old password</label>
                <input type="password" class="form-control" formControlName="oldPassword" [ngClass]="{ 'is-invalid': changePasswordForm.get('oldPassword')?.invalid && changePasswordForm.get('oldPassword')?.touched }">
                <span class="invalid-feedback" *ngIf="changePasswordForm.get('oldPassword')?.errors?.['required']">Old password is required.</span>
            </div>
            <div class="mb-3">
                <label class="form-label">New password</label>
                <input type="password" class="form-control" formControlName="newPassword" [ngClass]="{ 'is-invalid': changePasswordForm.get('newPassword')?.invalid && changePasswordForm.get('newPassword')?.touched }">
                <span class="invalid-feedback" *ngIf="changePasswordForm.get('newPassword')?.errors?.['required']">New password is required.</span>
            </div>
            <button [disabled]="changePasswordForm.invalid" type="submit" class="btn btn-dark col-12">Change password</button>
        </form>
    </div>
</div>