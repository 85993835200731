<nav class="navbar navbar-expand navbar-dark bg-custom">
  <div class="container">
    <a class="navbar-brand title" routerLink="/orders">Delivery Manager</a>
    <ul class="navbar-nav">
      <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link" routerLinkActive="active" routerLink="/orders">Orders</a>
      </li>
      <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link" routerLinkActive="active" routerLink="/admin">Admin</a>
      </li>
      <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link" routerLinkActive="active" routerLink="/register">Add User</a>
      </li>
      <li class="nav-item" *ngIf="isAuthenticated">
        <a class="nav-link logout-link" routerLinkActive="active" (click)="logout()">Logout</a>
      </li>
      <li class="nav-item" *ngIf="!isAuthenticated">
        <a class="nav-link" routerLinkActive="active" routerLink="/login">Login</a>
      </li>
    </ul>
  </div>
</nav>
